<template>
  <div class="home">
    <div class="container">
      <book-search />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BookSearch from "@/components/BookSearch.vue";

export default {
  name: "Home",
  components: {
    BookSearch
  }
};
</script>
