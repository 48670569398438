<template>
  <section class="sidebar-layout">
    <b-sidebar position="fixed" expand-on-hover fullheight reduce open>
      <b-menu>
        <b-menu-list label="Library">
          <b-menu-item icon="plus-box" label="Add a Book"></b-menu-item>
          <b-menu-item icon="bookshelf" label="Bookshelf"></b-menu-item>
        </b-menu-list>
      </b-menu>
    </b-sidebar>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
