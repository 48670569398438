import axios from "axios";

export default {
  async getOneBook(volumeId) {
    // console.log(`getOneBook called with book id: ${volumeId}`);
    try {
      var result = await axios({
        method: "GET",
        url: `https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/getBook/${volumeId}`
      });
      return result.data;
    } catch (error) {
      console.error(error);
      return "Error processing graphQL call: \n" + error;
    }
  }
};
