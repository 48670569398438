import axios from "axios";

export default {
  async addBook(book, owned, read, bookclub) {
    let isbn = "";
    if (typeof book.volumeInfo.industryIdentifiers !== "undefined") {
      book.volumeInfo.industryIdentifiers.forEach(i => {
        if (i.type === "ISBN_13") {
          isbn = i.identifier;
        }
      });
    } else {
      isbn == "";
    }
    let categories = [];
    if (typeof book.volumeInfo.categories !== "undefined") {
      categories = book.volumeInfo.categories;
    } else {
      categories = [];
    }
    const dataObj = {
      VolumeId: book.id,
      BookTitle: book.volumeInfo.title,
      authors: book.volumeInfo.authors,
      thumbUrl: book.volumeInfo.imageLinks.smallThumbnail,
      isbn13: isbn,
      categories: categories,
      googleLink: book.selfLink,
      shelfStatus: owned,
      readStatus: read,
      bookclub: bookclub,
      dateAdded: new Date(),
      lastUpdate: new Date()
    };
    let postData = JSON.stringify(dataObj);
    try {
      let result = await axios({
        method: "POST",
        url:
          "https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/addBook",
        data: postData
      });
      return {
        status: result.status,
        statusMsg: "success",
        book: {
          title: dataObj.BookTitle,
          volumeId: dataObj.VolumeId
        }
      };
      // return JSON.stringify(result.data);
    } catch (error) {
      console.error(error);
      return {
        status: error,
        statusMsg: "error",
        book: dataObj.BookTitle
      };
    }
  }
};

// mutation {
//     createBook(
//       input: {
//         book: {
//           volumeId: "2W6KDQAAQBAJ"
//           title: "We Should All be Feminists"
//           author: "Chimamanda Ngozi Adichie"
//           isbn13: "9781101911761"
//           categories: ["Political Science / Essays", "Social Science / Feminism & Feminist Theory","Social Science / Gender Studies"]
//           thumbUrl: "http://books.google.com/books/publisher/content?id=2W6KDQAAQBAJ&printsec=frontcover&img=1&zoom=5&edge=curl&imgtk=AFLRE723LyZ9oU4CE2iXVK0mlJ6OiZjav2-7fl_iaIibpyGHOrEhN_LwwK-7ZzRO7AQ83IcePXQnSVx4yXHA3lbLmF9ITi9O5WTHS8wnNSa0n62W9sUhycLKSYZZoORh0kS_wU2hDUPb&source=gbs_api"
//           googleLink: "https://www.googleapis.com/books/v1/volumes/2W6KDQAAQBAJ"
//           description: "\u003cb\u003eThe highly acclaimed, provocative \u003ci\u003eNew York Times\u003c/i\u003e bestseller from the award-winning author of \u003ci\u003eAmericanah, \u003c/i\u003e \"one of the world's great contemporary writers\" (Barack Obama).\u003c/b\u003e \u003cp\u003e In this personal, eloquently-argued essay--adapted from the much-admired TEDx talk of the same name--Chimamanda Ngozi Adichie offers readers a unique definition of feminism for the twenty-first century, one rooted in inclusion and awareness. Drawing extensively on her own experiences and her deep understanding of the often masked realities of sexual politics, here is one remarkable author's exploration of what it means to be a woman now--and an of-the-moment rallying cry for why we should all be feminists."
//           shelfStatus: "owned"
//           readStatus: "not-read"
//           bookclub: false
//         }
//       }
//     ) {
//       book {
//         bookId
//       }
//     }
//   }
