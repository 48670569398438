<template>
  <get-bookshelf />
</template>

<script>
import GetBookshelf from "@/components/GetBookshelf";

export default {
  components: { GetBookshelf }
};
</script>

<style></style>
