<template>
  <section>
    <b-dropdown :triggers="['click', 'hover']">
      <template #trigger>
        <b-button label="Shelf Status" type="is-info"></b-button>
      </template>
      <b-dropdown-item has-link>
        <router-link :to="{ path: '/library/bookshelf' }">All</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link>
        <router-link
          :to="{ path: '/library/bookshelf', query: { status: 'owned' } }"
          >Owned</router-link
        >
      </b-dropdown-item>
      <b-dropdown-item has-link>
        <router-link
          :to="{ path: '/library/bookshelf', query: { status: 'want' } }"
          >Wanted</router-link
        >
      </b-dropdown-item>
    </b-dropdown>
    <b-table
      :data="books"
      paginated
      pagination-position="both"
      per-page="15"
      :default-sort-direction="defaultSortDirection"
      default-sort="BookTitle"
    >
      <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
      <b-table-column
        field="BookTitle"
        label="Title"
        cell-class="has-text-left"
        searchable
        sortable
        v-slot="props"
      >
        <router-link
          :to="{ name: 'Book', params: { volumeId: props.row.VolumeId } }"
        >
          {{ props.row.BookTitle }}
        </router-link>
        <!-- {{ props.row.BookTitle }} -->
      </b-table-column>

      <b-table-column
        field="authors"
        label="Authors"
        cell-class="has-text-left"
        searchable
        sortable
        v-slot="props"
      >
        {{ props.row.authors.toString().replace(",", ", ") }}
      </b-table-column>

      <b-table-column
        field="isbn13"
        label="ISBN"
        cell-class="has-text-left"
        searchable
        sortable
        v-slot="props"
      >
        {{ props.row.isbn13 }}
      </b-table-column>

      <b-table-column
        field="categories"
        label="Categories"
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.categories.toString().replace(",", ", ") }}
      </b-table-column>

      <b-table-column
        field="readStatus"
        label="Read Status"
        sortable
        searchable
        v-slot="props"
      >
        <b-icon
          :icon="
            props.row.readStatus === 'true'
              ? 'check-circle'
              : props.row.readStatus === 'reading'
              ? 'book-open-page-variant'
              : ''
          "
          :type="
            props.row.readStatus === 'true'
              ? 'is-success'
              : props.row.readStatus === 'reading'
              ? 'is-primary'
              : 'is-info'
          "
        >
        </b-icon>
        <!-- {{ props.row.readStatus }} -->
      </b-table-column>

      <b-table-column
        field="shelfStatus"
        label="Owned"
        searchable
        sortable
        v-slot="props"
      >
        <b-icon
          :icon="props.row.shelfStatus === 'owned' ? 'bookshelf' : 'cart'"
          :type="props.row.shelfStatus === 'owned' ? 'is-success' : 'is-info'"
        ></b-icon>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import GetBookshelfMethod from "@/methods/GetBookshelfMethod";
// const data = require("@/testData/library.json");
export default {
  data() {
    return {
      // data,
      books: [],
      sortIcon: "arrow-up",
      defaultSortDirection: "asc",
      sortIconSize: "is-small",
      isLoading: true,
      statusQuery: ""
    };
  },
  methods: {
    async getBooks(shelfStatus) {
      // const graphQuery = "books";
      // const queryParam = `(orderBy: TITLE_ASC)`;
      // const nodes = "bookId, title, authors";
      var result = await GetBookshelfMethod.getAllBooks(shelfStatus);
      this.books = result;
      this.isLoading = false;
    }
  },
  created() {
    this.statusQuery = this.$route.query.status;
    this.getBooks(this.statusQuery);
    // this.books = data;
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === "Bookshelf") {
        this.statusQuery = newRoute.query.status;
        this.getBooks(this.statusQuery);
      }
    }
  }
};
</script>

<style></style>
