<template>
  <section>
    <!-- <p class="title"></p> -->
    <b-field horizontal label="Find a Book" label-position="on-border">
      <b-input
        v-model="author"
        placeholder="Author"
        @keyup.native.enter="searchBooks()"
      ></b-input>
      <b-input
        v-model="title"
        placeholder="Title"
        @keyup.native.enter="searchBooks()"
      ></b-input>
      <b-input
        v-model="isbn"
        placeholder="ISBN"
        @keyup.native.enter="searchBooks()"
      ></b-input>
    </b-field>
    <b-field horizontal>
      <p class="control">
        <b-button
          type="is-info"
          @click="searchBooks()"
          class="bookButton"
          size="is-medium"
        >
          Search
        </b-button>
        <b-button
          type="is-info is-light"
          @click="clearSearch()"
          class="bookButton"
          size="is-medium"
        >
          Clear
        </b-button>
      </p>
    </b-field>
    <div>
      <template v-if="addBookResponse.statusMsg === 'success'">
        <b-notification type="is-success">
          <router-link
            :to="{
              name: 'Book',
              params: { volumeId: addBookResponse.book.volumeId }
            }"
          >
            {{ addBookResponse.book.title }}
          </router-link>
          added successfully!</b-notification
        >
      </template>
      <template v-if="addBookResponse.statusMsg === 'error'">
        <b-notification type="is-warning"
          >There was a problem adding
          <u>{{ addBookResponse.book }}</u></b-notification
        >
      </template>
    </div>
    <b-notification
      type="is-danger is-light"
      role="alert"
      :closable="false"
      has-icon
      auto-close
      :duration="5000"
      v-if="emptySearch"
    >
      No books meeting search params. Try something different
    </b-notification>
    <div>
      <b-loading :is-full-page="false" v-model="searchLoading"></b-loading>
      <b-table
        :data="books"
        ref="table"
        :opened-detailed="[1]"
        detailed
        detail-key="id"
        :show-detail-icon="true"
        v-if="showBooks"
      >
        <b-table-column field="id" :visible="false" v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          field="BookTitle"
          cell-class="has-text-left"
          label="Title"
          sortable
          v-slot="props"
        >
          {{ props.row.volumeInfo.title }}
        </b-table-column>
        <b-table-column
          field="authors"
          cell-class="has-text-left"
          label="Authors"
          sortable
          v-slot="props"
        >
          {{
            props.row.volumeInfo.authors
              ? props.row.volumeInfo.authors.toString().replace(",", ", ")
              : "NA"
          }}
        </b-table-column>
        <b-table-column
          field="publishedDate"
          cell-class="has-text-left"
          label="Published Date"
          sortable
          v-slot="props"
        >
          {{ props.row.volumeInfo.publishedDate }}
        </b-table-column>

        <template #detail="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64">
                <img
                  :src="
                    props.row.volumeInfo.imageLinks
                      ? props.row.volumeInfo.imageLinks.smallThumbnail
                      : ''
                  "
                />
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <p>
                  {{ props.row.volumeInfo.description }}
                </p>
                <div>
                  <div class="block">
                    <b-switch
                      v-model="isOwned"
                      true-value="Owned"
                      false-value="Want"
                    >
                      {{ isOwned }}
                    </b-switch>
                  </div>
                  <div class="block">
                    <b-radio
                      v-model="isRead"
                      name="readStatus"
                      native-value="false"
                    >
                      Not Read
                    </b-radio>
                    <b-radio
                      v-model="isRead"
                      name="readStatus"
                      native-value="true"
                    >
                      Read
                    </b-radio>
                    <b-radio
                      v-model="isRead"
                      name="readStatus"
                      native-value="reading"
                    >
                      Reading
                    </b-radio>
                  </div>
                  <div class="block">
                    <b-switch v-model="bookclub">
                      Bookclub
                    </b-switch>
                  </div>
                  <b-button type="is-link is-dark" @click="addBook(props.row)"
                    >Add Book</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import AddBookMethod from "@/methods/AddBookMethod";

export default {
  name: "BookSearch",
  data() {
    return {
      labelPosition: "on-border",
      books: [],
      isbn: "",
      author: "",
      title: "",
      addBookResponse: {},
      showBooks: false,
      searchLoading: false,
      emptySearch: false,
      isOwned: "Owned",
      isRead: "false",
      bookclub: false
    };
  },
  methods: {
    async searchBooks() {
      this.searchLoading = true;
      this.emptySearch = false;
      this.showBooks = false;

      let queryParam = "";
      if (this.isbn) {
        queryParam = `isbn:${this.isbn.trim()}`;
      } else {
        if (this.author) {
          queryParam = `inauthor:${this.author.trim()}`;
        }
        if (this.title) {
          queryParam += ` intitle:${this.title.trim()}`;
        }
      }
      this.books = [];
      try {
        var result = await axios({
          method: "GET",
          params: {
            q: queryParam
          },
          url: "https://www.googleapis.com/books/v1/volumes"
        });
        var data = result.data;
      } catch (error) {
        console.error(error);
      }
      if (typeof data.items !== "undefined") {
        data.items.forEach(b => {
          this.books.push(b);
        });
        this.showBooks = true;
        this.searchLoading = false;
      } else {
        this.searchLoading = false;
        this.emptySearch = true;
      }
    },
    clearSearch() {
      this.author = "";
      this.title = "";
      this.isbn = "";
      this.showBooks = false;
      this.books = [];
    },
    async quickAddOwned(bookObject) {
      this.addBookResponse = await AddBookMethod.addBook(
        bookObject,
        "owned",
        "false",
        false
      );
    },
    async addBook(bookObject) {
      this.addBookResponse = await AddBookMethod.addBook(
        bookObject,
        this.isOwned.toLowerCase(),
        this.isRead,
        this.bookclub
      );
    }
  }
};
</script>

<style></style>
