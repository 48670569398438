import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import SearchBooks from "../views/library/SearchBooks.vue";
import Bookshelf from "@/views/library/Bookshelf.vue";
import Book from "@/views/library/Book.vue";
import AddNote from "@/views/library/AddNote.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/library/addBook",
    name: "AddBook",
    component: SearchBooks
  },
  {
    path: "/library/bookshelf",
    name: "Bookshelf",
    component: Bookshelf
  },
  {
    path: "/library/book/:volumeId",
    name: "Book",
    component: Book
  },
  {
    path: "/library/addnote",
    name: "AddNote",
    component: AddNote
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
