import axios from "axios";

export default {
  async updateBook(bookObj) {
    const newObj = {
      VolumeId: bookObj.volumeId,
      shelfStatus: bookObj.shelfStatus,
      readStatus: bookObj.readStatus,
      bookclub: bookObj.bookclub,
      lastUpdate: new Date()
    };
    const postData = JSON.stringify(newObj);
    try {
      let result = await axios({
        method: "POST",
        url:
          "https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/updateBook",
        data: postData
      });
      return {
        status: result.status,
        statusMsg: "success",
        volumeId: newObj.VolumeId
      };
    } catch (error) {
      console.error(error);
      return {
        status: error,
        statusMsg: "error",
        volumeId: newObj.VolumeId
      };
    }
  }
};
