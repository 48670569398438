import axios from "axios";

export default {
  async getAllBooks(status) {
    let endpoint =
      "https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/allBooks";
    let queryParams = "";
    if (status === "want") {
      endpoint =
        "https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/scanBooks";
      queryParams = {
        attribute: "shelfStatus",
        value: "want"
      };
    }
    if (status === "owned") {
      endpoint =
        "https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/scanBooks";
      queryParams = {
        attribute: "shelfStatus",
        value: "owned"
      };
    }
    //   let responseData  = {};
    try {
      var result = await axios({
        method: "GET",
        url: endpoint,
        params: queryParams
      });

      // console.log(result.data[0]);

      return result.data;
    } catch (error) {
      console.error(error);
      return "Error processing graphQL call: \n" + error;
    }
  }
};
