<template>
  <div v-if="showBook" class="content">
    <p class="title">{{ book.BookTitle }}</p>
    <p class="subtitle" v-if="!googleBookLoading">
      {{ googleBook.volumeInfo.subtitle }}
    </p>
    <p class="subtitle">By: {{ book.authors.toString().replace(",", ", ") }}</p>
    <article class="media">
      <div class="media-center">
        <figure class="image">
          <img :src="book.thumbUrl" />
        </figure>
      </div>
    </article>
    <div class="p-1">
      <b-icon
        :icon="book.shelfStatus === 'owned' ? 'bookshelf' : ''"
        :type="book.shelfStatus === 'owned' ? 'is-success' : ''"
        size="is-medium"
        custom-class="shelfIcon"
      ></b-icon>
      <b-icon
        :icon="
          book.readStatus === 'true'
            ? 'check-circle'
            : book.readStatus === 'reading'
            ? 'book-open-page-variant'
            : ''
        "
        :type="
          book.readStatus === 'true'
            ? 'is-success'
            : book.readStatus === 'reading'
            ? 'is-primary'
            : ''
        "
        size="is-medium"
        custom-class="shelfIcon"
      >
      </b-icon>
      <b-icon
        :icon="book.bookclub ? 'account-group' : ''"
        :type="book.bookclub ? 'is-primary' : ''"
        size="is-medium"
        custom-class="shelfIcon"
      ></b-icon>
    </div>
    <hr />
    <b-loading v-model="isLoading"></b-loading>
    <article class="media">
      <div class="media-content">
        <b-tabs v-model="activeTab" class="tabContent">
          <b-tab-item label="Description">
            <p v-if="showPowellsLink">
              <a :href="powellsLink" target="_blank">Search Powell's Books</a>
            </p>
            <p v-html="bookDescription"></p>
          </b-tab-item>
          <b-tab-item label="Quotes/Notes">
            Quotes and Notes Placeholder
          </b-tab-item>
          <b-tab-item label="More Info">
            <section>
              <div v-if="!googleBookLoading">
                <p>
                  Category:
                  {{ book.categories.toString().replace(",", ", ") }}
                </p>
                <p>Pages: {{ googleBook.volumeInfo.pageCount }}</p>
                <p>Publisher: {{ googleBook.volumeInfo.publisher }}</p>
              </div>
              <b-field
                horizontal
                label="Read Status"
                :label-position="labelPosition"
              >
                <b-select v-model="book.readStatus">
                  <option value="false">Not Read</option>
                  <option value="true">Read</option>
                  <option value="reading">Reading</option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Owned/Want"
                :label-position="labelPosition"
              >
                <b-select v-model="book.shelfStatus">
                  <option value="owned">Owned</option>
                  <option value="want">Want</option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Bookclub"
                :label-position="labelPosition"
              >
                <b-select v-model="book.bookclub">
                  <option value="true">True</option>
                  <option value="false">False</option>
                </b-select>
              </b-field>
              <b-button
                type="is-link is-dark"
                :loading="updatesLoading"
                @click="
                  updateBook(
                    bookId,
                    book.shelfStatus,
                    book.readStatus,
                    book.bookclub
                  )
                "
                >Update Book</b-button
              >
            </section>
          </b-tab-item>
        </b-tabs>
      </div>
    </article>
  </div>
  <!-- </div> -->
</template>

<script>
import GetBookMethod from "@/methods/GetBookMethod";
import UpdateBookMethod from "@/methods/UpdateBookMethod";
import axios from "axios";

export default {
  data() {
    return {
      labelPosition: "inside",
      isLoading: true,
      showBook: false,
      googleBookLoading: true,
      bookId: "",
      book: {},
      googleBook: {},
      bookDescription: "",
      activeTab: 0,
      bookUpdates: {},
      updatesLoading: false,
      showPowellsLink: false,
      powellsLink: ""
    };
  },
  methods: {
    async getBook(volumeId) {
      this.isLoading = true;
      var result = await GetBookMethod.getOneBook(volumeId);
      this.book = result;
      this.powellsLink = `https://www.powells.com/searchresults?keyword=${this.book.BookTitle}`;
      if (this.book.shelfStatus === "want") {
        this.showPowellsLink = true;
      }
      this.isLoading = false;
      this.showBook = true;
    },
    async updateBook(volumeId, shelfStatus, readStatus, bookclub) {
      this.updatesLoading = true;
      const bookObj = {
        volumeId: volumeId,
        shelfStatus: shelfStatus,
        readStatus: readStatus,
        bookclub: bookclub
      };
      // console.log(`VolID: ${bookObj.volumeId}\nreadStatus: ${readStatus}`);
      let result = await UpdateBookMethod.updateBook(bookObj);
      this.bookUpdates = result;
      this.updatesLoading = false;
    },
    async getGoogleBook(volumeId) {
      this.googleBookLoading = true;
      try {
        var result = await axios({
          method: "GET",
          url: `https://www.googleapis.com/books/v1/volumes/${volumeId}`
        });
        this.googleBook = result.data;
        this.bookDescription = result.data.volumeInfo.description;
      } catch (error) {
        console.error(error);
      }
      this.googleBookLoading = false;
    }
  },
  created() {
    this.bookId = this.$route.params.volumeId;
    this.getBook(this.bookId);
    this.getGoogleBook(this.bookId);
  },
  watch: {
    $route(newRoute) {
      this.bookId = newRoute.params.volumeId;
      if (newRoute.name === "Book") {
        this.getBook(this.bookId);
      }
    }
  }
};
</script>

<style>
.tabContent {
  text-align: left;
}

.shelfIcon {
  margin: 5px;
}
</style>
