import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import "./../node_modules/buefy/dist/buefy.css";
import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
// import "./../node_modules/@mdi/font/css/materialdesignicons.min.css";

Vue.config.productionTip = false;
Vue.use(Buefy);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
