<template>
  <section class="noteForm">
    <b-field label="Book" horizontal>
      <b-select
        :loading="loadingBooks"
        placeholder="Select a Book"
        v-model="noteObj.VolumeId"
        required
      >
        <option
          v-for="book in books"
          :key="book.VolumeId"
          :value="book.VolumeId"
          >{{ book.BookTitle }}</option
        >
      </b-select>
    </b-field>
    <b-field label="Page #" horizontal>
      <b-numberinput
        controls-alignment="right"
        controls-position="compact"
        required
        maxlength="4"
        v-model="noteObj.page"
      ></b-numberinput>
    </b-field>
    <b-field label="Note Type" horizontal>
      <b-select placeholder="Select Note Type" v-model="noteObj.type" required>
        <option value="quote">Quote</option>
        <option value="note">Note</option>
      </b-select>
    </b-field>
    <b-field label="Note to Add" horizontal>
      <b-input type="textarea" v-model="noteObj.text" required></b-input>
    </b-field>
    <b-field>
      <b-button
        type="is-link is-dark"
        :disabled="btnDisabled"
        :loading="noteAdding"
        @click="addNoteToDdb()"
      >
        Add Note
      </b-button>
    </b-field>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      books: [],
      noteObj: {},
      addNoteStatus: "",
      loadingBooks: false,
      noteAdding: false
    };
  },
  methods: {
    async getReading() {
      this.loadingBooks = true;
      try {
        var result = await axios({
          method: "GET",
          url:
            "https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/scanBooks",
          params: {
            attribute: "readStatus",
            value: "reading"
          }
        });
        this.books = result.data;
      } catch (error) {
        console.error(error);
      }
      this.loadingBooks = false;
    },
    async addNoteToDdb() {
      this.noteAdding = true;
      this.noteObj.page = parseInt(this.noteObj.page);
      const now = new Date();
      this.noteObj.NoteId = `${this.noteObj.VolumeId}-${Date.now(now)}`;
      this.noteObj.dateAdded = now;
      const noteJson = JSON.stringify(this.noteObj);

      try {
        var result = await axios({
          method: "POST",
          url:
            "https://mijs77i262.execute-api.us-west-2.amazonaws.com/Prod/addNote",
          data: noteJson
        });
        this.addNoteStatus = result.status;
        this.noteAdding = false;
        this.noteObj.text = "";
      } catch (error) {
        console.error(error);
      }
    }
  },
  created() {
    this.getReading();
  },
  computed: {
    btnDisabled() {
      return (
        !this.noteObj.VolumeId ||
        !this.noteObj.page ||
        !this.noteObj.type ||
        !this.noteObj.text
      );
    }
  }
};
</script>

<style>
.noteForm {
  text-align: left;
  max-width: 600px;
}
</style>
