<template>
  <div id="app">
    <div class="container">
      <div v-if="displayMenu" id="menu">
        <library-menu />
      </div>
      <div id="nav">
        <b-navbar>
          <template #start>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
              Home
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/about' }">
              About
            </b-navbar-item>
            <b-navbar-dropdown label="Library">
              <b-navbar-item
                tag="router-link"
                :to="{ path: '/library/addBook' }"
              >
                Add a Book
              </b-navbar-item>
              <b-navbar-item
                tag="router-link"
                :to="{ path: '/library/addNote' }"
              >
                Add a Note
              </b-navbar-item>
              <b-navbar-item
                tag="router-link"
                :to="{ path: '/library/bookshelf' }"
              >
                Bookshelf
              </b-navbar-item>
            </b-navbar-dropdown>
          </template>
        </b-navbar>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import LibraryMenu from "@/components/LibraryMenu";

export default {
  data() {
    return {
      displayMenu: false
    };
  },
  components: { LibraryMenu }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding-bottom: 30px;
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
